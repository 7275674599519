import Card from "components/Card";
import React from "react";

const CenterLayout = ({ children }) => {
    return (
        <div className="flex justify-center min-h-screen h-fit w-full bg-gradient-to-bl from-indigo-300 to-transparent bg-indigo-100">
            <div className="w-full max-w-screen-lg px-3 pt-[4svh] pb-[4svh] md:mt-[15vh] md:mb-[15vh]">
                <div className="mx-auto max-w-fit mt-3 mb-3 bg-white p-4 rounded-md shadow-xl">
                    {children}
                </div>
            </div>
        </div>
    )
};

export default CenterLayout;