import React, { useEffect, useState } from "react";
import Tabs from "components/Tabs";
import Tab from "components/Tabs/Tab";
import EventPage from "pages/Event";
import ClinicList from "pages/Clinic";
import TopNav from "common/components/TobNav";
import _ from "lodash";
import { memberClient } from "common/apis/member";
import LoginContainer from "containers/Account/Login";
import { useNavigate } from "react-router-dom";

const BookmarkPage = () => {
    const [userName, setUserName] = useState(null);
    const navigate = useNavigate();
    
    useEffect(() => {
        fetchMyInfo();
    }, []);

    const fetchMyInfo = async () => {
        try {
            const ret = await memberClient.get(`/member/my-info`);
            console.log("[fetchMyInfo] ret", ret);
            setUserName(ret?.data?.name);
        } catch (e) {
            console.error("[fetchMyInfo] error", e);
        }
    }
    
    if (_.isNil(userName)) {
        return (
            <div>
                <TopNav pages={[{ name: "찜" }]} disablePlus={true} />
                <div className="text-center text-gray-500 pt-5 pb-2">
                    로그인이 필요한 메뉴입니다
                </div>
                <LoginContainer />
            </div>
        )
    }
    
    return (
        <div>
            <TopNav pages={[{ name: "찜 목록" }]} disablePlus={true} />
            <Tabs tabsClassnames='sticky top-0 bg-white' isEvenFullWidth={true}>
                <Tab title='이벤트' path="events">
                    <EventPage  filterParams={{ limit: 1 }} />
                </Tab>
                <Tab title='의원' path="clinics">
                    <ClinicList filterParams={{ limit: 2 }} />
                </Tab>
            </Tabs>
        </div>
    )
}

export default BookmarkPage;
