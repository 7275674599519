import React, { useEffect, useState } from "react";
import { UserIcon, ChevronRightIcon, BanknotesIcon } from "@heroicons/react/24/outline";
import PageTitle from "components/Title/Page";
import TopNav from "common/components/TobNav";
import { memberClient } from "common/apis/member";

import _ from "lodash";
import LoginContainer from "containers/Account/Login";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const MyPage = () => {
    const [userName, setUserName] = useState(null);
    const navigate = useNavigate();
    
    useEffect(() => {
        fetchMyInfo();
    }, []);

    const fetchMyInfo = async () => {
        try {
            const ret = await memberClient.get(`/member/my-info`);
            console.log("[fetchMyInfo] ret", ret);
            setUserName(ret?.data?.name);
        } catch (e) {
            console.error("[fetchMyInfo] error", e);
        }
    }

   const onLogoutButtonClicked = async () => {
        try {
            const ret = await memberClient.post(`/auth/logout`);
            console.log("[onLogoutButtonClicked] ret", ret);
            // window.location.replace("/login");
            toast.success("로그아웃 되었습니다.");
            // window.location.replace("/home");
            navigate("/home");
        } catch (e) {
            console.error("[onLogoutButtonClicked] error", e);
        }
    }

    if (_.isNil(userName)) {
        return (
            <div>
                <TopNav pages={[{ name: "마이페이지" }]} disablePlus={true} />
                <div className="text-center text-gray-500 pt-5 pb-2">
                    로그인이 필요한 메뉴입니다
                </div>
                <LoginContainer />
            </div>
        )
    }

    return (
        <div>
            <TopNav pages={[{ name: "마이페이지" }]} disablePlus={true} />
            <div className="p-2 flex flex-col space-y-2">
                <div className="shadow ring-1 ring-black ring-opacity-5 w-full">
                    <div className="flex p-2">
                        <div className="mr-4 flex items-center">
                            <div className="rounded-full border border-pink-500 p-1 text-pink-500">
                                <UserIcon className="size-8" />
                            </div>
                            <div>
                                <div className="flex items-center">
                                    <h4 className="text-lg font-bold ps-3">{userName}</h4>
                                    <ChevronRightIcon className="size-4" />
                                </div>
                                {/* <div>
                                    <h4 className="text-sm px-3 text-gray-500">sakura0401</h4>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shadow ring-1 ring-black ring-opacity-5 w-full cursor-pointer" onClick={onLogoutButtonClicked}>
                    <div className="flex p-2">
                        <div className="mr-4 flex items-center">
                            <div>
                                <div className="flex items-center">
                                    <h4 className="text-lg ps-3">로그아웃</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="shadow ring-1 ring-black ring-opacity-5 w-full">
                    <div className="flex p-2">
                        <div className="mr-4 flex items-center">
                            <div>
                                <div className="flex items-center">
                                    <h4 className="text-lg ps-3">문의</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shadow ring-1 ring-black ring-opacity-5 w-full">
                    <div className="flex p-2">
                        <div className="mr-4 flex items-center">
                            <div>
                                <div className="flex items-center">
                                    <h4 className="text-lg ps-3">설정</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default MyPage;