import axios from "axios";
import CryptoJS from "crypto-js";
import { responseErrorInterceptor } from "./staff";

export const memberClient = axios.create({
    baseURL: "https://rest.miview.shop",
    // baseURL: "http://localhost:8101",
    withCredentials: true,
});

export namespace MemberClient {
    export const loginViaLine = async (lineCode: string) => {
        try {
            const body = {
                line_code: lineCode
            }
            const ret = await memberClient.post(`/member/line/login`, body);
            console.log("[loginViaLine] ret", ret);
            return ret.data;
        } catch (e) {
            console.error("[loginViaLine] error", e);
            throw e;
        }
    }
}