import { StarIcon } from "@heroicons/react/20/solid";
import { ReviewPhotoCard } from "common/pages/ReviewDetail";
import BasicArticle from "components/Article/Basic";
import Badges from "components/Badges";
import Card from "components/Card";
import SpinnerMedium from "components/Spinner/Medium";
import { truncate } from "components/Table/Simple/presets";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const ReviewCard = ({ review }) => {
    const navigate = useNavigate();

    return (
        <div className="cursor-pointer" onClick={() => {
            navigate(`/contents/review/${review.id}`);
        }}>
            <div className="">
                {/* <img
                        src={UrlUtils.convertCdnUrl(review.mainImage)}
                        alt="profileImage"
                        className="h-24 w-24 p-2 object-center group-hover:opacity-75"
                    /> */}
                <div className="p-2 flex flex-col space-y-2">
                    <div className="flex items-center space-x-2">
                        <h1 className="text-lg font-bold">
                            {`${review.title}`}
                        </h1>
                        <div className="flex">
                            <StarIcon className="w-4 h-4 text-yellow-400" />
                            <span className="text-gray-500 text-xs">
                                {'5.0'}
                            </span>
                            {/* <span className="ms-[2px] text-gray-600 text-xs">
                                    {`口コミ${10}件症例${50}件`}
                                </span> */}
                        </div>
                    </div>
                    <div className="flex items-center space-x-2">
                        <div>
                            <Badges
                                items={review.treatments.map((item) => item.name)}
                                isRemovable={false}
                            />
                        </div>
                    </div>
                    <div className="mt-3 overflow-auto">
                        <div className="flex space-x-3 pb-3 min-w-max">
                            {review?.beforePhotos?.length > 0 && review.beforePhotos.map((url, idx) => (
                                <ReviewPhotoCard
                                    key={idx}
                                    url={url}
                                    idx={idx}
                                    type="전"
                                />
                            ))}
                            {review?.afterPhotos?.length > 0 && review.afterPhotos.map((url, idx) => (
                                <ReviewPhotoCard
                                    key={idx}
                                    url={url}
                                    idx={idx}
                                    type="후"
                                />
                            ))}
                        </div>
                    </div>
                    <BasicArticle>
                        <div className="text-sm leading-normal">
                            {truncate(review.content, 100)}
                        </div>
                    </BasicArticle>
                    {/* <div className="flex items-center">
                            <span className="text-pink-700 text-xs">
                                {`¥${numeral(price).format("0,0")}`}
                            </span>
                            <span className="text-gray-600 text-[10px] ps-1">
                                {` | 通常価格：¥${numeral(originPrice).format("0,0")}`}
                            </span>
                        </div> */}
                    {/* <div>
                            <Badges
                                items={doctor.specialties.map((sp) => sp.name)}
                                isRemovable={false}
                            />
                        </div> */}
                </div>
            </div>
        </div>
    )
}

const ClinicReviewList = ({ clinicInfo }) => {
    console.log("[ClinicReviewList] clinicInfo", clinicInfo);
    const [reviews, setReviews] = useState(null);

    useEffect(() => {
        if (_.isNil(clinicInfo.reviews)) {
            console.log("[ClinicReviewList] clinicInfo.reviews is empty");
            return;
        }
        setReviews(clinicInfo.reviews);
    }, [clinicInfo])

    if (_.isNil(reviews)) {
        return <SpinnerMedium />
    }
    console.log("[ClinicReviewList] events", reviews);

    if (_.isEmpty(reviews)) {
        return (
            <div>
                <div className="text-center text-gray-500 py-10">
                    작성된 리뷰가 없습니다
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col space-y-2 mt-2">
            {reviews.map((review, idx) => (
                <div>
                    <ReviewCard key={idx} review={review} />
                    <div className="border-t border-gray-200 mt-2"></div>
                </div>
            ))}
        </div>
    )
}

export default ClinicReviewList;
