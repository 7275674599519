import React from "react";
import Search from "common/components/Search";

const SearchPage = ()=>{
    return (
        <div>
            <Search></Search>
        </div>
    )
}

export default SearchPage;
