import Search from 'common/components/Search';
import React, { useEffect, useState } from 'react';
import { infoService } from 'common/services/info';
import EmptyForm from 'components/Form/Empty';
import PageTitle from 'components/Title/Page';
import axios from 'axios';
import { EllipsisVerticalIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { staffClient } from 'common/apis/staff';
const MAX_DISPLAY = 5;

const SearchPage = ({ onSelect = () => { } }) => {
    const navigate = useNavigate();
    const [filterText, setFilterText] = useState("");
    const [doctors, setDoctors] = useState([]);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        fetchEvents();
        fetchDoctors();
    }, [])

    const fetchEvents = async () => {
        try {
            // const ret = await axios.get("https://rest.miview.shop/event");
            const ret = await staffClient.get("/event");
            console.log("[fetchEvents] ret", ret);
            setEvents(ret.data.data);
        } catch (e) {
            console.error("[fetchEvents] error", e);
        }
    }

    const fetchDoctors = async () => {
        try {
            // const ret = await axios.get("https://rest.miview.shop/doctor");
            const ret = await staffClient.get("/doctor");
            console.log("[fetchDoctors] ret", ret);
            setDoctors(ret.data.data);
        } catch (e) {
            console.error("[fetchDoctors] error", e);
        }
    }

    const onInputChanged = (value) => {
        console.log("onInputChanged", value);
        setFilterText(value);
    }

    const filteredSpecialties = infoService.specialties
        .filter((elm) => elm.name.includes(filterText))
    console.log("SIZE", filteredSpecialties.length);
    const filteredTreatment = infoService.treatments
        .filter((elm) => elm.name.includes(filterText))
    const filteredClinics = infoService.clinics
        .filter((elm) => elm.name.includes(filterText))
    const filteredDoctors = doctors
        .filter((elm) => elm.name.includes(filterText))
    const filteredEvents = events
        .filter((elm) => elm.title.includes(filterText))

    return (
        <div className="w-[250px]">
            <div className="flex flex-col space-y-2">
                <div className="flex justify-between items-center">
                    <PageTitle>
                        통합검색
                    </PageTitle>
                    <div>
                        <XCircleIcon className="w-5 h-5 cursor-pointer text-gray-500" onClick={() => {
                            onSelect();
                        }} />
                    </div>
                </div>
                <Search
                    placeholder="검색어 입력"
                    onChange={onInputChanged}
                    hideButton={true}
                />
                <div>
                    <div className="text-md font-bold">
                        진료과목
                    </div>
                    <div className="text-sm flex flex-col space-y-1 px-1">
                        {filteredSpecialties
                            .filter((elm, i) => i < MAX_DISPLAY)
                            .map((item, index) => (
                                <EmptyForm>
                                    <div className="py-1 px-2 cursor-pointer" onClick={() => {
                                        navigate(`/contents?specialtyId=${item.id}`)
                                        onSelect();
                                    }}>
                                        {item.name}
                                    </div>
                                </EmptyForm>
                            ))}
                        <div hidden={MAX_DISPLAY >= filteredSpecialties.length}>
                            <div className="flex justify-center">
                                <EllipsisVerticalIcon className="size-5"></EllipsisVerticalIcon>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="text-md font-bold">
                        시술명
                    </div>
                    <div className="text-sm flex flex-col space-y-1 px-1">
                        {filteredTreatment
                            .filter((elm, i) => i < MAX_DISPLAY)
                            .map((item, index) => (
                                <EmptyForm>
                                    <div className="py-1 px-2 cursor-pointer" onClick={() => {
                                        navigate(`/contents?specialtyId=${item.id}&treatmentId=${item.id}`)
                                        onSelect();
                                    }}>
                                        {item.name}
                                    </div>
                                </EmptyForm>
                            ))}
                        <div hidden={MAX_DISPLAY >= filteredTreatment.length}>
                            <div className="flex justify-center">
                                <EllipsisVerticalIcon className="size-5"></EllipsisVerticalIcon>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="text-md font-bold">
                        의원명
                    </div>
                    <div className="text-sm flex flex-col space-y-1 px-1">
                        {filteredClinics
                            .filter((elm, i) => i < MAX_DISPLAY)
                            .map((item, index) => (
                                <EmptyForm>
                                    <div className="py-1 px-2 cursor-pointer" onClick={() => {
                                        navigate(`/contents/clinic/${item.id}`)
                                        onSelect();
                                    }}>
                                        {item.name}
                                    </div>
                                </EmptyForm>
                            ))}
                        <div hidden={MAX_DISPLAY >= filteredClinics.length}>
                            <div className="flex justify-center">
                                <EllipsisVerticalIcon className="size-5"></EllipsisVerticalIcon>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="text-md font-bold">
                        의사명
                    </div>
                    <div className="text-sm flex flex-col space-y-1 px-1">
                        {filteredDoctors
                            .filter((elm, i) => i < MAX_DISPLAY)
                            .map((item, index) => (
                                <EmptyForm>
                                    <div className="py-1 px-2 cursor-pointer" onClick={() => {
                                        navigate(`/contents/doctor/${item.id}`)
                                        onSelect();
                                    }}>
                                        {item.name}
                                    </div>
                                </EmptyForm>
                            ))}
                        <div hidden={MAX_DISPLAY >= filteredDoctors.length}>
                            <div className="flex justify-center">
                                <EllipsisVerticalIcon className="size-5"></EllipsisVerticalIcon>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="text-md font-bold">
                        이벤트명
                    </div>
                    <div className="text-sm flex flex-col space-y-1 px-1">
                        {filteredEvents
                            .filter((elm, i) => i < MAX_DISPLAY)
                            .map((item, index) => (
                                <EmptyForm>
                                    <div className="py-1 px-2 cursor-pointer" onClick={() => {
                                        navigate(`/contents/event/${item.id}`)
                                        onSelect();
                                    }}>
                                        {item.title}
                                    </div>
                                </EmptyForm>
                            ))}
                        <div hidden={MAX_DISPLAY >= filteredEvents.length}>
                            <div className="flex justify-center">
                                <EllipsisVerticalIcon className="size-5"></EllipsisVerticalIcon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchPage;
