import React, { useEffect } from "react";
import lineLogo from 'assets/logo/line.svg';
import naverLogo from 'assets/logo/naver.png';
import googleLogo from 'assets/logo/google.png';
import kakaoLogo from 'assets/logo/kakao.svg';
import Card from "components/Card";
import { toast } from "react-toastify";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { memberClient } from "common/apis/member";

export const handleLoginMessage = (e: MessageEvent) => {
    console.log("[Login] message received", e.data);
    const { refreshToken, token } = e.data;
    if (_.isNil(refreshToken) || _.isNil(token)) {
        return false;
    }
    toast.success("로그인이 완료되었습니다.", { toastId: "line-login-success" });
    return true;
}

const LOGIN_POPUP_OPTIONS = 'width=600, height=600, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no';
const LoginContainer = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener('message', (e) => {
            const ok = handleLoginMessage(e);
            if (!ok) {
                return;
            }
            window.removeEventListener('message', () => { });
            navigate("/");
        });
    }, [])

    const onLineLoginButtonClicked = async () => {
        console.log("[onLineLoginButtonClicked]");
        const ret = await memberClient.get(`/member/line/url`);
        const url = ret.data;
        console.log("[onLineLoginButtonClicked] url", url);
        const popupWindow = window.open(url, 'newWindow', LOGIN_POPUP_OPTIONS);
    }

    const onNaverLoginButtonClicked = async () => {
        const ret = await memberClient.get(`/member/naver/url`);
        const url = ret.data;
        console.log("[onNaverLoginButtonClicked] url", url);
        const popupWindow = window.open(url, 'newWindow', LOGIN_POPUP_OPTIONS);
    }

    const onGoogleLoginButtonClicked = async () => {
        const ret = await memberClient.get(`/member/google/url`);
        const url = ret.data;
        console.log("[onGoogleLoginButtonClicked] url", url);
        const popupWindow = window.open(url, 'newWindow', LOGIN_POPUP_OPTIONS);
    }

    const onKakaoLoginButtonClicked = async () => {
        const ret = await memberClient.get(`/member/kakao/url`);
        const url = ret.data;
        console.log("[onKakaoLoginButtonClicked] url", url);
        const popupWindow = window.open(url, 'newWindow', LOGIN_POPUP_OPTIONS);
    }

    return (
        <div>
            <div className="p-2 flex flex-col">
                <Card>
                    <div className="p-3 flex justify-center cursor-pointer" onClick={onLineLoginButtonClicked}>
                        <img src={lineLogo} alt="line-logo" className="w-7 h-7 mr-2" />
                        <div className="self-center">
                            {"라인 계정으로 로그인"}
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="p-3 flex justify-center cursor-pointer" onClick={onNaverLoginButtonClicked}>
                        <img src={naverLogo} alt="line-logo" className="w-7 h-7 mr-2" />
                        <div className="self-center">
                            {"네이버 계정으로 로그인"}
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="p-3 flex justify-center cursor-pointer" onClick={onGoogleLoginButtonClicked}>
                        <img src={googleLogo} alt="line-logo" className="w-7 h-7 mr-2" />
                        <div className="self-center">
                            {"구글 계정으로 로그인"}
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="p-3 flex justify-center cursor-pointer" onClick={onKakaoLoginButtonClicked}>
                        <img src={kakaoLogo} alt="line-logo" className="w-7 h-7 mr-2" />
                        <div className="self-center">
                            {"카카오톡 계정으로 로그인"}
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default LoginContainer;