
import React from "react";
import { Routes, Route } from "react-router-dom";
import LineOAuthPage from "common/pages/OAuth/Line";
import NaverOAuthPage from "common/pages/OAuth/Naver";
import GoogleOAuthPage from "common/pages/OAuth/Google";
import KakaoOAuthPage from "common/pages/OAuth/Kakao";

export const OAuthRoutes = () => (
    <Routes>
        <Route path="/line" element={<LineOAuthPage />}></Route>
        <Route path="/naver" element={<NaverOAuthPage />}></Route>
        <Route path="/google" element={<GoogleOAuthPage />}></Route>
        <Route path="/kakao" element={<KakaoOAuthPage />}></Route>
    </Routes>
)

export default OAuthRoutes;
