const CDN_URL = 'https://file.miview.shop';

export namespace UrlUtils {
    export const convertCdnUrl = (url: string)=>{
        let path = ""
        try{
            const urlObj = new URL(url);
            path = urlObj.pathname;
        }catch(e){
            path = url;
        }
        return `${CDN_URL}${path}`;
    }
}