import React, { useEffect, useState } from 'react';
import logo from 'assets/logo/logo-no-background.png';
import Card from 'components/Card';
import { data as clinicData } from 'pages/Clinic/example';
import { data as eventData } from 'pages/Event/example';
import { data as reviewData } from 'pages/Review/example';
import { ClinicCard } from 'pages/Clinic';
// import { EventCard } from 'pages/Event';
// import { ReviewCard } from 'pages/Review';
import PageTitle from 'components/Title/Page';
import Button from 'components/Form/Button';
import { ChevronRightIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import Search from 'common/components/Search';
import { parts } from './example';
import axios from 'axios';
import { infoService } from 'common/services/info';
import { EventCard } from 'common/pages/ClinicDetail/ClinicEventList';
import { ReviewCard } from 'common/pages/ClinicDetail/ClinicReviewList';
import TopNav from 'common/components/TobNav';

const Home = () => {
    const navigate = useNavigate();
    const [events, setEvents] = useState(null);
    const [reviews, setReviews] = useState(null);

    useEffect(() => {
        fetchEvents();
        fetchReviews();
    }, [])

    const fetchEvents = async () => {
        try {
            const ret = await axios.get("https://rest.miview.shop/event");
            console.log("[fetchEvents] ret", ret);
            setEvents(ret.data.data);
        } catch (e) {
            console.error("[fetchEvents] error", e);
        }
    }

    const fetchReviews = async () => {
        try {
            const ret = await axios.get("https://rest.miview.shop/review");
            console.log("[fetchReviews] ret", ret);
            setReviews(ret.data.data);
        } catch (e) {
            console.error("[fetchReviews] error", e);
        }
    }

    return (
        <div>
            <TopNav />
            <div className="flex flex-col space-y-2">
                <div className="px-2 pt-2">
                    <PageTitle>
                        진료과목 선택
                    </PageTitle>
                </div>
                <div className="grid grid-cols-3 px-2 gap-3 px-4">
                    {infoService.getSpecialties().map((item, index) => (
                        <div className="flex justify-center shadow ring-1 ring-black ring-opacity-5 rounded-lg cursor-pointer" onClick={() => { navigate(`/contents?specialtyId=${item.id}`) }}>
                            <div className="p-2">
                                <div className="flex justify-center text-pink-300">
                                    {item?.data?.icon ?
                                        <div className="size-7">
                                            <img src={item?.data?.icon} alt="icon" />
                                        </div>
                                        :
                                        <UserCircleIcon className="w-7 h-7" />
                                    }
                                </div>
                                <span className="flex justify-center text-sm text-nowrap">
                                    {item.name}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
                <div>
                    <div className="border-t border-gray-200 border-4"></div>
                    <div className="px-2 pt-2">
                        <PageTitle>
                            인기 의원
                        </PageTitle>
                    </div>
                    <div>
                        {infoService.getClinics()
                            .filter((elm, i) => i < 4)
                            .map((item, index) => (
                                <ClinicCard
                                    clinic={item}
                                />
                            ))}
                    </div>
                    <div className="px-2">
                        <Button onButtonClicked={() => { navigate("/contents?tabId=clinics") }}>
                            <span>
                                모든 의원 보기
                            </span>
                            <ChevronRightIcon className="size-4" />
                        </Button>
                    </div>
                </div>
                <div>
                    <div className="border-t border-gray-200 border-4"></div>
                    <div className="px-2 pt-2">
                        <PageTitle>
                            인기 이벤트
                        </PageTitle>
                    </div>
                    <div>
                        {events?.map((item, index) => (
                            <Card>
                                <EventCard
                                    event={item}
                                />
                            </Card>
                        ))}
                    </div>
                    <div className="px-2">
                        <Button onButtonClicked={() => { navigate("/contents?tabId=events") }}>
                            <span>
                                모든 이벤트 보기
                            </span>
                            <ChevronRightIcon className="size-4" />
                        </Button>
                    </div>
                </div>
                <div>
                    <div className="border-t border-gray-200 border-4"></div>
                    <div className="px-2 pt-2">
                        <PageTitle>
                            인기 후기
                        </PageTitle>
                    </div>
                    <div>
                        {reviews?.map((item, index) => (
                            <Card>
                                <ReviewCard
                                    review={item}
                                />
                            </Card>
                        ))}
                    </div>
                    <div className="px-2">
                        <Button onButtonClicked={() => { navigate("/contents?tabId=reviews") }}>
                            <span>
                                모든 후기 보기
                            </span>
                            <ChevronRightIcon className="size-4" />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
