import { memberClient } from "common/apis/member";
import TopNav from "common/components/TobNav";
import LoginContainer from "containers/Account/Login";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ReservationPage = () => {
    const [userName, setUserName] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchMyInfo();
    }, []);

    const fetchMyInfo = async () => {
        try {
            const ret = await memberClient.get(`/member/my-info`);
            console.log("[fetchMyInfo] ret", ret);
            setUserName(ret?.data?.name);
        } catch (e) {
            console.error("[fetchMyInfo] error", e);
        }
    }

    if (_.isNil(userName)) {
        return (
            <div>
                <TopNav pages={[{ name: "예약" }]} disablePlus={true} />
                <div className="text-center text-gray-500 pt-5 pb-2">
                    로그인이 필요한 메뉴입니다
                </div>
                <LoginContainer />
            </div>
        )
    }

    return (
        <div>
            <TopNav pages={[{ name: "예약내역" }]} disablePlus={true} />
            <div className="text-center text-gray-500 py-10">
                예약 내역이 없습니다
            </div>
        </div>
    )
}

export default ReservationPage;
