import { memberClient } from "common/apis/member";
import CenterLayout from "common/layouts/Center";
import SpinnerMedium from "components/Spinner/Medium";
import PageTitle from "components/Title/Page";
import React, { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

export const LineOAuthPage = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        onRendered();
    }, []);


    const onRendered = async () => {
        console.log("THIS IS LINE LOGIN PAGE");
        const code = searchParams.get("code");
        const state = searchParams.get("state");
        console.log("LINE LOGIN CODE", code, state);
        let authInfo = {};
        try {
            // alert("LINE LOGIN CODE" + code + state)
            const ret = await memberClient.post('/member/line/login', { line_code: code });
            console.log("[Login Page] /member/line/login ret", ret);
            const { token, refreshToken } = ret.data;
            // localStorage.setItem("refreshToken", refreshToken);
            authInfo = { token, refreshToken };
            const agent = window.navigator.userAgent.toLowerCase();
            if (agent.includes("line/")) {
                toast.success("라인 앱에서 로그인 되었습니다.");
                navigate(`/`);
                return;
            }
        } catch (e) {
            // window.alert("라인 로그인 오류"));
            toast.error("회원 정보를 찾을 수 없습니다.");
            console.error("[Login Page] /member/line/login error", e);
            // window.close();
        }
        try {
            console.log("[Login Page] window.opener.postMessage", authInfo);
            window.opener.postMessage(authInfo, "*");
            window.close();
        } catch (e) {
            // maybe new browser
            console.log("window.opener.postMessage error", e);
            toast.info("새로운 브라우저에서 로그인 되었습니다.");
            navigate(`/`);
        }
    }
    return (
        <CenterLayout>
            <div className="flex flex-col space-y-2">
                <PageTitle>Line Login</PageTitle>
                <SpinnerMedium></SpinnerMedium>
            </div>
        </CenterLayout>
    )
}

export default LineOAuthPage;