import { infoService } from "common/services/info";
import SpinnerMedium from "components/Spinner/Medium";
import Tabs from "components/Tabs";
import Tab from "components/Tabs/Tab";
import PageTitle from "components/Title/Page";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ClinicInfo from "./ClinicInfo";
import ClinicDoctorList from "./ClinicDoctorList";
import axios from "axios";
import ClinicEventList from "./ClinicEventList";
import ClinicReviewList from "./ClinicReviewList";
import TopNav from "common/components/TobNav";
import { staffClient } from "common/apis/staff";
import { UrlUtils } from "common/utils/url";

const ClinicDetail = () => {
    const { clinicId } = useParams();
    const [clinicInfo, setClinicInfo] = useState(null);

    useEffect(() => {
        if (clinicId === "pv") {
            console.log("[ClinicDetail] clinic id is empty it's maybe preview page");
            console.log("[ClinicDetail] try get from localstorage");
            const tempClinicInfoStr = localStorage.getItem("tempClinicInfo");
            if (_.isNil(tempClinicInfoStr)) {
                console.log("[ClinicDetail] tempClinicInfo is empty");
                return;
            }
            const tempClinicInfo = JSON.parse(tempClinicInfoStr);
            console.log("[ClinicDetail] tempClinicInfo", tempClinicInfo);
            setClinicInfo(tempClinicInfo);
            return;
        }
        fetchClinicInfo();
    }, [clinicId]);

    const fetchClinicInfo = async () => {
        if (_.isNil(clinicId)) {
            return;
        }
        try {
            // const ret = await axios.get(`https://rest.miview.shop/clinic/${clinicId}`);
            const ret = await staffClient.get(`/clinic/${clinicId}`);
            console.log("[fetchClinicInfo] ret", ret.data);
            setClinicInfo(ret.data);
        } catch (e) {
            console.error("[fetchClinicInfo] error", e);
        }
    }

    if (_.isNil(clinicInfo)) {
        return <SpinnerMedium />;
    }

    return (
        <div>
            <TopNav
                pages={[
                    {
                        name: "탐색",
                        path: "/contents"
                    },
                    {
                        name: "의원",
                        path: "/contents?tabId=clinics"
                    },
                    {
                        name: clinicInfo.name
                    }
                ]}
                disablePlus={true}
            />
            <div hidden={_.isEmpty(clinicInfo.bannerImage)}>
                <img src={UrlUtils.convertCdnUrl(clinicInfo.bannerImage)} alt="banner" />
            </div>
            <div className="p-3">
                <div className="flex flex-col">
                    <div className="text-lg font-bold self-center">
                        {clinicInfo.name}
                    </div>
                    <div className="text-sm text-gray-500 self-center">
                        {"서울 ・ 강남역"}
                    </div>
                </div>
            </div>
            <div className="border-t border-gray-200"></div>
            <Tabs tabsClassnames='sticky top-0 bg-white' isEvenFullWidth={true}>
                <Tab title='병원정보' path="info">
                    <ClinicInfo clinicInfo={clinicInfo} />
                </Tab>
                <Tab title='의료진' path="doctor">
                    <ClinicDoctorList clinicInfo={clinicInfo} />
                </Tab>
                <Tab title='이벤트' path="event">
                    <ClinicEventList clinicInfo={clinicInfo} />
                </Tab>
                <Tab title='시술후기' path="review">
                    <ClinicReviewList clinicInfo={clinicInfo} />
                </Tab>
            </Tabs>
        </div>
    )
}

export default ClinicDetail;
