import React, { useEffect, useState } from "react";
import Card from "components/Card";
import { StarIcon } from "@heroicons/react/20/solid";
import PageTitle from "components/Title/Page";
import Search from "common/components/Search";
import numeral from "numeral";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { EventCard } from "common/pages/ClinicDetail/ClinicEventList";
import _ from "lodash";
import SpinnerMedium from "components/Spinner/Medium";
import { DoctorCard } from "common/pages/ClinicDetail/ClinicDoctorList";

const DoctorPage = ({ filterParams = { offset: 0, limit: 10000 } as any }) => {
    const [doctors, setDoctors] = useState(null);

    useEffect(() => {
        if(_.isNil(filterParams)) {
            return;
        }
        fetchDoctors();
    }, [filterParams])

    const fetchDoctors = async () => {
        console.log("[DoctorPage] fetchDoctors filterParams", filterParams);
        const response = await axios.get("https://rest.miview.shop/doctor", { params: filterParams });
        console.log("@@@", response)
        setDoctors(response.data.data);
    }

    if (_.isNil(doctors)) {
        return (
            <SpinnerMedium />
        )
    }

    return (
        <div>
            <div className="px-2 pt-2 text-sm">
                {`${doctors?.length}명의 의사가 검색되었습니다`}
            </div>
            {doctors?.map((item, index) => (
                <Card>
                    <DoctorCard
                        doctor={item}
                    />
                </Card>
            ))}
        </div>
    )
}

export default DoctorPage;